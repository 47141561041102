import * as React from "react";
import { withPrefix } from "gatsby";
import { sendBaselUserEmail } from "../../services/apiCalls";
import { sendBaselBoothEmail } from "../../services/apiCalls";
import Dialog from '@mui/material/Dialog';
import axios from 'axios';

const BASE_URL = process.env.GATSBY_BASE_URL;

export default function Final({ boothImage, userEmail, carousel, generatedSku }) {

  //Email ME: start
  const [openEmailMeModal, setOpenEmailMeModal] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [isRegistered, setIsRegistered] = React.useState(userEmail);

  const sendEmail = async () => {
    const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;

    if (email && emailRegex.test(email)) {
      setOpenEmailMeModal(false);
      setIsRegistered(true);

      const response = await sendBaselUserEmail({
        email : email,
        source : 'Art-Basel', 
      });

      axios.post(`${BASE_URL}/api/v1/carousels/baselsave`, {
        carousel_uID: carousel.carousel_uID,
        carousel_created_time: Date.now(),
        carousels: generatedSku,
        thumbnail: carousel.thumbnail,
        upscaled: carousel.upscaled,
        userID: response.userId,
      });

      sendBaselBoothEmail({ email: email, image: boothImage, source: 'Art-Basel' });
    }
    
  }
  //Email ME: end

  return (
    <div className="container">

      <div className="compare-brand">
        <div className="sub-heading">Introducing:</div>
        <div className="cl-wrap">
          <div className="cl"><img src={withPrefix("assets/img/brand-99-generations.svg")} alt="brand-99-generations" /></div>
          <div className="cl">X</div>
          <div className="cl">
            <img src={withPrefix("assets/img/brand-booth-ai.svg")} alt="brand-booth-ai" />
          </div>
        </div>
      </div>

      <div className="pro-name">Ta-da!</div>

      <div className="pro-image">
        <div className="image-wrap">
          <div className="image">
            <ImageItem src={boothImage} alt="photo-02" />
          </div>
        </div>
      </div>
      {(isRegistered && !openEmailMeModal) ? (
        <div className="note-box3">Don’t worry, we’ve just now e-mailed this to you!</div>
      ) : (
        <div className="btn-sm">
          <input
            type="submit" id="submit-c" className="btn btn-blue open-submit-image2"
            value="E-mail me this"
            onClick={() => setOpenEmailMeModal(true)}
          />
        </div>
      )}

      <div className="pro-brand-bx">
        <div className="logo"><img src={withPrefix("assets/img/logo-art-basel.png")} alt="logo-art-basel" /></div>
        <p>Thank you for enjoying our Art Basel Miami 2023 Demo.</p>
      </div>

      <Dialog onClose={() => setOpenEmailMeModal(false)} open={openEmailMeModal}>
        <div className="art-basel">
          <div className="modal submit-image-modal" id="submit-image" role="dialog" onClick={() => setOpenEmailMeModal(false)}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                <div
                  className="close"
                  onClick={() => setOpenEmailMeModal(false)}
                  style={{ background: `url(${withPrefix('assets/img/icon-close.svg')})` }}
                >Close</div>

                <div className="heading-top">
                  <h2>You’re almost done!</h2>
                  <p>Let’s save your creation, we will e-mail you.</p>
                </div>

                <div className="submit-form">
                  <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="search-c" id="search-c" placeholder="Enter E-mail" />
                  <input
                    type="submit" id="submit-c" className="btn btn-blue open-submit-image2"
                    value="Submit"
                    onClick={sendEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )

}

const ImageItem = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <div style={{ position: 'relative', paddingTop: '100%' }}>
      <img
        className="merchImages"
        id="merchImages"
        src={src}
        alt={alt}
        style={{
          opacity: imageLoaded ? 1 : 0,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onLoad={() => setImageLoaded(true)}
      />
      {!imageLoaded && (
        <img
          src={withPrefix("assets/img/green-little-balls.gif")}
          className="merchImages"
          id="merchImages"
          alt={alt}
          style={{
            opacity: imageLoaded ? 0 : 1,
            position: 'absolute',
            top: 0,
            left: 0,
            background: '#fff',
          }}
        />
      )}
    </div>
  )
}
