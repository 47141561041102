import * as React from 'react';
import { withPrefix } from "gatsby";

export default function Header() {
  const [open, setOpen] = React.useState(false);


  return (
    <header className="header-sm">
      <div className="container">

        <div className="brand">
          <a href="index.html" title="99 Generations">
            <img src={withPrefix("assets/img/brand-99-generations.svg")} alt="brand-99-generations" />
          </a>
        </div>

        <div className={`menu-btn menu-bar ${open ? 'active': ''}`} onClick={() => setOpen((open) => !open)}>
          <div className="icon-bar"><span></span></div>
        </div>

        <div className="nav-bar" style={{ display: open ? 'block': 'none' }}>
          <div className="nav-inn">


            <div className="menu-close menu-bar">
              <img onClick={() => setOpen(false)} src={withPrefix('assets/img/icon-close.svg')} />
            </div>


            <div className="top-wrap">
              <p>Hello Art | Basel,</p>
              <p>We're thrilled to invite you to preview the future of AI and eCommerce. In the last year, 99Gens has transformed from a simple product creator into so much more! We've become an all-encompassing platform that takes a single art image and wondrously transforms it into over 45 high-quality Cut-&-Sew items.</p>
              <p>During today's demo, you'll see how your artwork seamlessly turns into patterns for our expert print-on-demand partners to craft into tangible products. Plus, our platform automatically sets up your eCommerce presence, enabling product drops in merely 3 clicks. Finish the demo now to sign up instantly.</p>
            </div>
            <div className="btm-wrap">
              <div className="name">— Zach & Austin Hurst 99Gens Founders</div>
              <p>We look forward to seeing your generations.</p>
            </div>
          </div>
        </div>

        <div className={`overlay ${open ? 'show': ''}`} onClick={() => setOpen(false)}></div>

      </div>
    </header>
  )
}

